import styles from "./banner-more-sold.module.scss";
import { FC, useContext } from "react";
import { CTAButton, SectionElement } from "@/components";
import scssStyles from "@/utils/scssStyles";
import { Context } from "@/context";
import { dataMenuLabels, links } from "@/data/menu.data";
import BANNER_MORE_SOLD_ASSETS from "@/assets/banner-more-sold-asset";

const reference = dataMenuLabels().find((c) => c.slug === "mais-vendido");

const BannerMoreSold: FC = () => {
  const { state } = useContext(Context);
  return (
    <SectionElement
      id="BannerMaisVendido"
      navReference={reference}
      className={scssStyles([styles.sectionContainer])}
    >
      <div className={styles.lettering}>
        <h1 className={
          styles.title
        }>
          O carro mais<br />
          vendido do Brasil
        </h1>
        <p className={
          styles.description
        }>
          Pelo 4º ano consecutivo, a Fiat Strada conquista o país<br />
          inteiro. Líder absoluta de vendas, a picape está pronta<br />para qualquer desafio. Garanta já a sua!
        </p>

        <CTAButton
          className={styles.ctaButton}
          href={links.banner_mais_vendido}
          text="Compre a sua"
          title="Compre a sua"
        />
      </div>


      
        <img
          src={state.layout.isDesktop ? BANNER_MORE_SOLD_ASSETS.desktop.cars : BANNER_MORE_SOLD_ASSETS.mobile.cars}
          alt="Carros"
          className={styles.cars}
        />

    </SectionElement>
  );
};

export default BannerMoreSold;
