import { Cards, Chevron, Display, SectionElement } from "@/components";
import {
  IHero,
  dataHero,
  heroFirstAltTag,
  heroFirstBackground,
} from "@/data/hero.data";
import { dataMenuLabels } from "@/data/menu.data";
import scssStyles from "@/utils/scssStyles";
import React, { FC } from "react";
import styles from "./HeroDesktop.module.scss";

export const HeroDesktop: FC = () => {
  const previous = React.useRef<(() => void) | null>(null);
  const next = React.useRef<(() => void) | null>(null);

  const items: IHero[] | any = [];

  const reference = dataMenuLabels().find(c => c.slug === "");

  items.push({
    body: (
      <div
        className={scssStyles([styles.slides, styles.mainSlide])}
        title={"Nova fiat Strada com motor turbo"}
      >
        <div className={styles.svg1}> </div>
        <div className={styles.svg2}> </div>
      </div>
    ),
    background: {
      src: heroFirstBackground,
      alt: heroFirstAltTag,
    },
  });

  dataHero
    .map(item =>
      items.push({
        body: (
          <div className={scssStyles([styles.slides])}>
            <div
              className={styles.fullBgGradient}
              title={item.titleTag}
            />
            <div className={styles.content}>
              <div className={styles.descriptionContent}>
                <div className={styles.descriptionLayoutTwo}>
                  <h2 className={styles.tltBottom}>{item.title}</h2>
                  <div className={styles.line} />
                  <p>{item.description}</p>
                </div>
              </div>
            </div>
          </div>
        ),
        background: {
          src: item.backgrounds[0],
          alt: item.altTag,
        },
      })
    );

  return (
    <SectionElement
      noPadding
      id="desktopDisplay"
      className={styles.container}
      navReference={reference}
      handleKeyDown={(e, isVisible) => {
        if (isVisible) {
          if (e.key === "ArrowLeft") {
            previous?.current && previous?.current();
          } else if (e.key === "ArrowRight") {
            next?.current && next?.current();
          }
        }
      }}
    >
      <Display
        previousEvent={e => {
          previous.current = e;
        }}
        nextEvent={e => {
          next.current = e;
        }}
        items={items}
      />
      <div className={styles.scrollImage}>

      <p >Arraste para saber mais
      </p>
      <Chevron direction="down" color='white' thicker />
      </div>
      <Cards
        items={dataHero
          .map(d => ({
            ...d,
            src: d.cardSrc,
            title: d.cardTitle,
            alt: d.altTag,
          }))}
      />
    </SectionElement>
  );
};
