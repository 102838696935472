import { PREFIX } from '@/utils/imagePrefixes';

const BANNER_MORE_SOLD_PREFIX = `${PREFIX}banner-more-sold/`;

const BANNER_MORE_SOLD_ASSETS = {
    mobile: {
        background: `${BANNER_MORE_SOLD_PREFIX}background/background-mobile.webp`,
        cars: `${BANNER_MORE_SOLD_PREFIX
            }cars/cars-mobile.webp`,
        
    },
    desktop: {
        background: `${BANNER_MORE_SOLD_PREFIX}background/background-desktop.webp`,
        cars: `${BANNER_MORE_SOLD_PREFIX
            }cars/cars-desktop.webp`,
    }
};

export default BANNER_MORE_SOLD_ASSETS;
